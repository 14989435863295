import {
  LAST_OPENED_SERVICES_ADD,
  LAST_OPENED_SERVICES_DELETE,
  LAST_OPENED_SERVICES_PATCH,
  LAST_OPENED_SERVICES_ACTIVE_FILTER_UPDATE,
} from '../actions/types';

export const openedServiceExists = (openedServices, service) => {
  return openedServices.some((os) => os.id === service.id);
};

export function isOldestServiceVersion(openedServices, service) {
  return openedServices.some(
    (os) => os.id === service.id && os.version <= service.version
  );
}

export function isSameServiceVersion(openedServices, service) {
  return openedServices.some(
    (os) => os.id === service.id && os.version === service.version
  );
}

export default function (state = [], action) {
  switch (action.type) {
    case LAST_OPENED_SERVICES_ADD: {
      if (action.payload.id > 0) {
        if (state.length > 0 && openedServiceExists(state, action.payload)) {
          if (isSameServiceVersion(state, action.payload)) {
            return state.map((os) =>
              os.id === action.payload.id &&
              os.version === action.payload.version
                ? action.payload
                : os
            );
          } else {
            return [...state, action.payload];
          }
        } else {
          return [...state, action.payload];
        }
      } else {
        return [...state];
      }
    }
    case LAST_OPENED_SERVICES_DELETE: {
      return state.filter((service) => {
        let shouldStay = !(
          service.concatenatedName === action.payload.serviceName &&
          service.project === action.payload.project
        );
        if (action.payload.version !== undefined) {
          shouldStay = shouldStay || service.version !== action.payload.version;
        }
        if (action.payload.branch) {
          shouldStay = shouldStay || service.branch !== action.payload.branch;
        }
        return shouldStay;
      });
    }
    case LAST_OPENED_SERVICES_ACTIVE_FILTER_UPDATE: {
      return state.map((service) =>
        service.id === action.payload.id &&
        service.version === action.payload.version
          ? action.payload.service
          : service
      );
    }
    case LAST_OPENED_SERVICES_PATCH: {
      return state.map((service) =>
        service.id === action.payload.id &&
        service.version === action.payload.version
          ? { ...service, ...action.payload.service }
          : service
      );
    }

    default:
      return state;
  }
}
