// Core Entities reducer

import {
  CORE_ENTITIES_BEGIN,
  CORE_ENTITIES_SUCCESS,
  CORE_ENTITIES_FAILURE,
} from '../actions/types';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CORE_ENTITIES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CORE_ENTITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case CORE_ENTITIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
