import {
  ISSUES_FETCH_BEGIN,
  ISSUES_FETCH_SUCCESS,
  ISSUES_FETCH_FAILURE,
  ISSUE_TRANSACTION_BEGIN,
  ISSUE_TRANSACTION_SUCCESS,
  ISSUE_TRANSACTION_FAILURE,
} from '../actions/types';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ISSUES_FETCH_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ISSUES_FETCH_SUCCESS:
      return {
        data: action.payload.issues,
        loading: false,
      };
    case ISSUES_FETCH_FAILURE:
      return {
        data: [],
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
    case ISSUE_TRANSACTION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ISSUE_TRANSACTION_SUCCESS:
      return {
        data: action.payload.issues,
        loading: false,
        error: null,
      };
    case ISSUE_TRANSACTION_FAILURE:
      return {
        data: [],
        loading: false,
        error: action.payload.error,
      };
  }
};
