//Swagger reducer

import {
  SWAGGER_GET_BEGIN,
  SWAGGER_GET_SUCCESS,
  SWAGGER_GET_FAILURE,
  SWAGGER_GET_CLEAN,
} from '../actions/types';

const initialState = {
  data: {},
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SWAGGER_GET_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SWAGGER_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case SWAGGER_GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        data: {},
      };
    case SWAGGER_GET_CLEAN:
      return initialState;
    default:
      return state;
  }
}
