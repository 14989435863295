import {
  SERVICE_MIN_VERB_FETCH_BEGIN,
  SERVICE_MIN_VERB_FETCH_SUCCESS,
  SERVICE_MIN_VERB_FETCH_FAILURE,
  SERVICE_MIN_ENTITY_FETCH_BEGIN,
  SERVICE_MIN_ENTITY_FETCH_SUCCESS,
  SERVICE_MIN_ENTITY_FETCH_FAILURE,
  SERVICE_MIN_PROJECT_FETCH_BEGIN,
  SERVICE_MIN_PROJECT_FETCH_SUCCESS,
  SERVICE_MIN_PROJECT_FETCH_FAILURE,
  SERVICE_MIN_MODULE_FETCH_BEGIN,
  SERVICE_MIN_MODULE_FETCH_SUCCESS,
  SERVICE_MIN_MODULE_FETCH_FAILURE,
  SERVICE_CREATION_BEGIN,
  SERVICE_CREATION_SUCCESS,
  SERVICE_CREATION_FAILURE,
  SERVICE_CREATION_CLEAN,
  SERVICE_CREATION_TAB_CHANGE,
} from '../actions/types';

const initialState = {
  verbList: {
    data: [],
    loading: false,
    error: null,
  },
  entityList: {
    data: [],
    loading: false,
    error: null,
  },
  projectList: {
    data: [],
    loading: false,
    error: null,
  },
  moduleList: {
    data: [],
    loading: false,
    error: null,
  },
  serviceCreation: {
    data: [],
    loading: false,
    error: null,
  },
  activeTab: '1',
};
export default (state = initialState, action) => {
  switch (action.type) {
    case SERVICE_MIN_VERB_FETCH_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors.
      return {
        ...state,
        verbList: {
          loading: true,
          error: null,
          data: [],
        },
      };

    case SERVICE_MIN_VERB_FETCH_SUCCESS:
      // Success: set loading to false.
      // Also, replace the servicesList with the one fetched from api.

      return {
        ...state,
        verbList: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };

    case SERVICE_MIN_VERB_FETCH_FAILURE:
      // The request failed. It's done. So set loading to "false".
      // Save the error, so we can display it somewhere.
      // Since it failed, we don't have a service to display anymore, so empty service object
      return {
        ...state,
        verbList: {
          loading: false,
          error: action.payload.error,
          data: [],
        },
      };
    case SERVICE_MIN_ENTITY_FETCH_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors.
      return {
        ...state,
        entityList: {
          loading: true,
          error: null,
          data: [],
        },
      };

    case SERVICE_MIN_ENTITY_FETCH_SUCCESS:
      // Success: set loading to false.
      // Also, replace the servicesList with the one fetched from api.

      return {
        ...state,
        entityList: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };

    case SERVICE_MIN_ENTITY_FETCH_FAILURE:
      // The request failed. It's done. So set loading to "false".
      // Save the error, so we can display it somewhere.
      // Since it failed, we don't have a service to display anymore, so empty service object
      return {
        ...state,
        entityList: {
          loading: false,
          error: action.payload.error,
          data: [],
        },
      };

    case SERVICE_MIN_PROJECT_FETCH_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors.
      return {
        ...state,
        projectList: {
          loading: true,
          error: null,
          data: [],
        },
      };

    case SERVICE_MIN_PROJECT_FETCH_SUCCESS:
      // Success: set loading to false.
      // Also, replace the servicesList with the one fetched from api.

      return {
        ...state,
        projectList: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };

    case SERVICE_MIN_PROJECT_FETCH_FAILURE:
      // The request failed. It's done. So set loading to "false".
      // Save the error, so we can display it somewhere.
      // Since it failed, we don't have a service to display anymore, so empty service object
      return {
        ...state,
        projectList: {
          loading: false,
          error: action.payload.error,
          data: [],
        },
      };

    case SERVICE_MIN_MODULE_FETCH_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors.
      return {
        ...state,
        moduleList: {
          loading: true,
          error: null,
          data: [],
        },
      };

    case SERVICE_MIN_MODULE_FETCH_SUCCESS:
      // Success: set loading to false.
      // Also, replace the servicesList with the one fetched from api.

      return {
        ...state,
        moduleList: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };

    case SERVICE_MIN_MODULE_FETCH_FAILURE:
      // The request failed. It's done. So set loading to "false".
      // Save the error, so we can display it somewhere.
      // Since it failed, we don't have a service to display anymore, so empty service object
      return {
        ...state,
        moduleList: {
          loading: false,
          error: action.payload.error,
          data: [],
        },
      };

    case SERVICE_CREATION_TAB_CHANGE:
      return {
        ...state,
        activeTab: action.payload,
      };

    case SERVICE_CREATION_BEGIN:
      return {
        ...state,
        serviceCreation: {
          loading: true,
          error: null,
          data: [],
        },
      };
    case SERVICE_CREATION_SUCCESS:
      return {
        ...state,
        serviceCreation: {
          loading: false,
          error: null,
          data: [],
        },
      };

    case SERVICE_CREATION_FAILURE:
      return {
        ...state,
        serviceCreation: {
          loading: false,
          error: action.payload.error,
          data: [],
        },
      };

    case SERVICE_CREATION_CLEAN:
      return {
        ...state,
        serviceCreation: {
          loading: false,
          error: null,
          data: [],
        },
      };

    default:
      return state;
  }
};
