import {
  ERRORS_FETCH_BEGIN,
  ERRORS_FETCH_SUCCESS,
  ERRORS_FETCH_FAILURE,
  ERROR_TRANSACTION_BEGIN,
  ERROR_TRANSACTION_SUCCESS,
  ERROR_TRANSACTION_FAILURE,
  ERROR_LIST_ALL_BY_SERVICE_BEGIN,
  ERROR_LIST_ALL_BY_SERVICE_SUCCESS,
  ERROR_LIST_ALL_BY_SERVICE_FAILURE,
} from '../actions/types';

const initialState = {
  data: {
    content: [],
    pageable: {},
    totalPages: 0,
    totalElements: 0,
  },
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ERRORS_FETCH_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ERRORS_FETCH_SUCCESS:
      return {
        data: action.payload.errors,
        loading: false,
      };
    case ERRORS_FETCH_FAILURE:
      return {
        data: {
          content: [],
          pageable: {},
          totalPages: 0,
          totalElements: 0,
        },
        loading: false,
        error: action.payload.error,
      };
    case ERROR_TRANSACTION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ERROR_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ERROR_TRANSACTION_FAILURE:
      return {
        data: {
          content: [],
          pageable: {},
          totalPages: 0,
          totalElements: 0,
        },
        loading: false,
        error: action.payload.error,
      };
    case ERROR_LIST_ALL_BY_SERVICE_BEGIN:
      return {
        ...state,
        loading: true
      };
    case ERROR_LIST_ALL_BY_SERVICE_SUCCESS:
    case ERROR_LIST_ALL_BY_SERVICE_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
