import {
  LAST_OPENED_SERVICES_NAMELIST_ADD,
  LAST_OPENED_SERVICES_NAMELIST_REMOVE,
  LAST_OPENED_SERVICES_NAMELIST_PATCH,
} from '../actions/types';
import {
  isOldestServiceVersion,
  openedServiceExists,
} from './lastOpenedServicesReducer';

export default function (state = [], action) {
  switch (action.type) {
    case LAST_OPENED_SERVICES_NAMELIST_ADD: {
      if (action.payload.id > 0) {
        if (state.length > 0 && openedServiceExists(state, action.payload)) {
          if (isOldestServiceVersion(state, action.payload)) {
            const newState = state.filter((s) => s.id !== action.payload.id);
            newState.unshift(action.payload);
            return newState;
          } else {
            return state;
          }
        } else {
          if (state.length > 6) {
            const newState = state.slice(0, -1);
            newState.unshift(action.payload);
            return newState;
          } else {
            return [...state, action.payload];
          }
        }
      } else {
        return [...state];
      }
    }
    case LAST_OPENED_SERVICES_NAMELIST_REMOVE: {
      if (state.length > 0 && state.some((s) => s.id === action.payload)) {
        return state.filter((service) => service.id !== action.payload);
      } else {
        return state;
      }
    }
    case LAST_OPENED_SERVICES_NAMELIST_PATCH: {
      if (state.length > 0) {
        return state.map((service) =>
          service.id === action.payload.id
            ? { ...service, ...action.payload.service }
            : service
        );
      } else {
        return state;
      }
    }
    default:
      return state;
  }
}
