import { COPY_FORMAL_PARAMETER } from '../actions/types';

const int32Array = new Uint32Array(1);

const initialState = {
  data: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case COPY_FORMAL_PARAMETER:
      return {
        data: action.payload.copyFormalParameterValue,
      };
    default:
      return state;
  }
};
