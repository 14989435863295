import {
  COPY_INSTRUCTIONS,
  SET_SELECTED_INSTRUCTIONS,
  SET_IS_CUT,
  SET_PASTE_BELOW,
} from '../actions/types';

const initialState = {
  copy: [],
  selectedInstructions: [],
  isCut: false,
  isPasteBelow: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case COPY_INSTRUCTIONS:
      return {
        ...state,
        copy: action.payload.copyInstructionsValue,
        loading: false,
      };
    case SET_SELECTED_INSTRUCTIONS:
      return {
        ...state,
        selectedInstructions: action.payload,
      };
    case SET_IS_CUT:
      return {
        ...state,
        isCut: action.payload,
      };
    case SET_PASTE_BELOW:
      return {
        ...state,
        isPasteBelow: action.payload,
      };
    default:
      return state;
  }
};
