// Users reducer

import {
  DELETE_USER_INFO_LOGOUT,
  DELETE_USER_INFO_EXPIRED_SESSION,
  USER_BEGIN,
  USER_SUCCESS,
  USER_FAILURE,
} from '../actions/types';

const initialState = {
  userInfo: {},
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DELETE_USER_INFO_LOGOUT:
    case DELETE_USER_INFO_EXPIRED_SESSION:
      return {
        ...state,
        userInfo: {},
        error: null,
      };
    case USER_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case USER_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: action.payload,
      };
    case USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        userInfo: {},
      };
    default:
      return state;
  }
}
