// Profiles reducer

import {
  TRANSITIONS_FETCH_BEGIN,
  TRANSITIONS_FETCH_SUCCESS,
  TRANSITIONS_FETCH_FAILURE,
} from '../actions/types';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TRANSITIONS_FETCH_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TRANSITIONS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.transitions,
      };
    case TRANSITIONS_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        data: [],
      };
    default:
      return state;
  }
}
