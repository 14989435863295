// Profiles reducer

import {
  PROFILES_FETCH_BEGIN,
  PROFILES_FETCH_SUCCESS,
  PROFILES_FETCH_FAILURE,
  PROFILES_TRANSACTION_BEGIN,
  PROFILES_TRANSACTION_SUCCESS,
  PROFILES_TRANSACTION_FAILURE
} from '../actions/types';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PROFILES_FETCH_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case PROFILES_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.profiles,
      };
    case PROFILES_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        data: [],
      };
    case PROFILES_TRANSACTION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case PROFILES_TRANSACTION_SUCCESS:
      return {
        data: action.payload.profilesData,
        loading: false,
        error: null,
      };
    case PROFILES_TRANSACTION_FAILURE:
      return {
        data: [],
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
