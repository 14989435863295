// Core Entities reducer

import {
  FETCH_ENTITIES_PARAMETER_BEGIN,
  FETCH_ENTITIES_PARAMETER_SUCCESS,
  FETCH_ENTITIES_PARAMETERS_FAILURE,
  ADD_LOCAL_VARIABLE,
  ADD_LOCAL_VARIABLES,
  CLEAR_LOCAL_VARIABLES,
} from '../actions/types';

const initialState = {
  data: {
    entities: [],
  },
  loading: false,
  error: null,
  localVariables: [],
};

function addVariable(variable, localVariables) {
  const treeData = [...localVariables];
  if (!treeData.length) {
    treeData.splice(0, 0, {
      key: 'value-local',
      title: 'local',
      value: 'value-local',
      children: [],
      selectable: false,
    });
  }

  const parents = variable.split('.');
  let ptr = treeData[0];
  let key = 'value-local';
  // Loops through the tree and creates inner "parents", then assigns the last parent of the variable into ptr
  for (let i = 1; i < parents.length - 1; i++) {
    const value = parents[i];
    key += '.' + value;
    let parent = ptr.children.find((node) => node.title === value);
    if (!parent) {
      parent = {
        key: `${key}`,
        value: `${key}`,
        title: value,
        name: value,
        type: undefined,
        domain: undefined,
        datamodel: undefined,
        children: [],
      };
      ptr.children.push(parent);
    }
    ptr = parent;
  }
  if (
    ptr.children.every((node) => node.title !== parents[parents.length - 1])
  ) {
    ptr.children.push({
      key: `${key}-${parents[parents.length - 1]}`,
      value: `${key}-${parents[parents.length - 1]}`,
      title: parents[parents.length - 1],
      name: parents[parents.length - 1],
      type: undefined,
      domain: undefined,
      datamodel: undefined,
      children: [],
    });
  }
  return treeData;
}

export default function coreEntityReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ENTITIES_PARAMETER_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_ENTITIES_PARAMETER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: {
          ...state.data,
          entities: action.payload.tree,
        },
      };

    case FETCH_ENTITIES_PARAMETERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        data: {
          ...state.data,
          entities: [],
        },
      };

    case ADD_LOCAL_VARIABLE: {
      const { value } = action.payload;
      const treeData = addVariable(value, state.localVariables);
      return {
        ...state,
        localVariables: treeData,
      };
    }

    case ADD_LOCAL_VARIABLES: {
      const { variables } = action.payload;
      let treeData = state.localVariables;
      for (const variable of variables) {
        treeData = addVariable(variable.value.split('value.')[1], treeData);
      }
      return {
        ...state,
        localVariables: treeData,
      };
    }

    case CLEAR_LOCAL_VARIABLES:
      return {
        ...state,
        localVariables: [],
      };

    default:
      return state;
  }
}
