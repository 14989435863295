import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import authorizationServerService from "../api/authorizationServerService";

const PrivateRoute = ({ render, ...rest }) => {
  const userInfo = useSelector((state) => state.loginFlow.userData.userInfo);

  return (
    <Route
      {...rest}
      render={({ location, match }) => {
        if (
          localStorage.getItem('Access-Token') &&
          userInfo.active !== undefined &&
          userInfo.active
        ) {
          return render(match.params);
        } else {
          return (
            <Redirect
              push
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          );
        }
      }}
    />
  );
};

export default PrivateRoute;
