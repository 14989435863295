import {
  UPDATE_MODAL_ISSUE,
} from '../actions/types';

const initialState = {
  modalIssueVisibility: false,
  serviceStatus: null
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_MODAL_ISSUE:
      return {
        ...state,
        modalIssueVisibility: action.payload.modalIssueVisibility,
        serviceStatus: action.payload.serviceStatus,
      };
    default:
      return state;
  }
};
