import {
  SERVICE_MIN_SEARCH_BEGIN,
  SERVICE_MIN_SEARCH_SUCCESS,
  SERVICE_MIN_SEARCH_FAILURE,
  SERVICE_MIN_SEARCH_CLEAN,
} from '../actions/types';

const initialState = {
  data: {},
  loading: false,
  error: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case SERVICE_MIN_SEARCH_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors.
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SERVICE_MIN_SEARCH_SUCCESS:
      // Success: set loading to false.
      // Also, replace the servicesList with the one fetched from api.
      return {
        ...state,
        loading: false,
        data: action.payload.service,
      };
    case SERVICE_MIN_SEARCH_FAILURE:
      // The request failed. It's done. So set loading to "false".
      // Save the error, so we can display it somewhere.
      // Since it failed, we don't have a service to display anymore, so empty service object
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        data: {},
      };
    case SERVICE_MIN_SEARCH_CLEAN:
      return {
        loading: false,
        error: null,
        data: {},
      };
    default:
      return state;
  }
};
