// Service search reducer

import {
  SERVICES_SEARCH_BEGIN,
  SERVICES_SEARCH_SUCCESS,
  SERVICES_SEARCH_FAILURE,
  SERVICES_SEARCH_CLEAN,
} from '../actions/types';

const initialState = {
  data: {
    content: [],
    totalElements: 0,
  },
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SERVICES_SEARCH_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors.
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SERVICES_SEARCH_SUCCESS:
      // Success: set loading to false.
      // Also, replace the servicesList with the one fetched from api.
      return {
        ...state,
        loading: false,
        data: action.payload.services,
      };
    case SERVICES_SEARCH_FAILURE:
      // The request failed. It's done. So set loading to "false".
      // Save the error, so we can display it somewhere.
      // Since it failed, we don't have a service to display anymore, so empty service object
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        data: {
          content: [],
          totalElements: 0,
        },
      };
    case SERVICES_SEARCH_CLEAN: {
      return {
        ...state,
        loading: false,
        data: { content: [], totalElements: 0 },
        error: null,
      };
    }
    default:
      return state;
  }
}
