// Parent Service reducer
import {
  SERVICE_FROM_INSTRUCTION_FETCH_SUCCESS,
  SERVICE_FROM_INSTRUCTION_FETCH_DELETE,
} from '../actions/types';

const initialState = {
  parent: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SERVICE_FROM_INSTRUCTION_FETCH_SUCCESS: {
      return {
        parent: action.payload.parentService,
      };
    }
    case SERVICE_FROM_INSTRUCTION_FETCH_DELETE:
      return initialState;
    default:
      return state;
  }
}
